/* eslint-disable camelcase */
import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { COLOR_CONSTANTS, radius, space } from 'theme'
import { countArticleReadingTime, parseTextFromServer, stripHtml } from 'helpers'
import { toHumanReadable } from 'utils/date'
import { H2, Text } from 'components/Typography'
import { Flex } from 'components/Layout'
import LocalImage from 'components/LocalImage'
import CategoryItem from './CategoryItem'

const StyledLink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  color: initial;
  cursor: pointer;

  &:visited {
    color: initial;
  }

  margin-bottom: ${space.l};
  padding-bottom: ${space.m};
  background-color: ${COLOR_CONSTANTS.WHITE};
  border: 1px solid #b6cef0;
  border-radius: 12px;
  padding: ${space.m};

  img {
    transform: scale(1);
    transition: all 400ms ease;
  }

  &:hover {
    box-shadow: 0px 0px 10px rgba(145, 158, 171, 0.3);

    h2 {
      text-decoration: underline;
    }

    img {
      transform: scale(1.02);
    }
  }
`

const StyledImg = styled(LocalImage)`
  object-fit: contain;
  width: 100%;
  height: 100%;

  & > picture > img {
    object-fit: contain !important;
  }

  border-radius: ${radius.l};
`

const BlogItem = ({ featured_media, date: createdAt, title, content, slug, categories }) => {
  if (!featured_media) {
    console.error('no featured media')
  }
  const { source_url } = featured_media || {}
  const parsedTitle = parseTextFromServer(title)
  const readingTime = countArticleReadingTime({ text: stripHtml(content) })

  return (
    <StyledLink href={`/insights/${slug}/`}>
      <Flex width="100%" flexDirection="column">
        <Flex justifyContent="center">
          {source_url && <StyledImg src={source_url} alt={parsedTitle} width="100%" />}
        </Flex>
        <Flex mt="m" flexDirection="column">
          <Flex justifyContent="space-between">
            <Text color="secondaryText">{toHumanReadable(createdAt)}</Text>
            <Text color="secondaryText">{readingTime} min read</Text>
          </Flex>
          <Flex mt="m" height={{ mobile: 'auto', desktop: '90px' }}>
            <Text fontWeight="bold" fontSize="xl" color={COLOR_CONSTANTS.DENIM} textAlign="left">
              {parsedTitle}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex mt="s" flexWrap="wrap">
        {categories.map((category, index) => (
          <CategoryItem key={index} category={category} />
        ))}
      </Flex>
    </StyledLink>
  )
}

BlogItem.defaultProps = {
  categories: [],
  featured_media: {},
}

BlogItem.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  categories: PropTypes.array,
  featured_media: PropTypes.object,
  date: PropTypes.string.isRequired,
}

export default BlogItem
