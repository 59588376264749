import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, radius, space } from 'theme'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import { usePagination, DOTS } from './usePagination'

const PaginationNumber = styled(Flex)`
  height: 48px;
  width: 48px;
  min-width: 48px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.primary};
  text-align: center;
  margin: ${space.s} ${space.m} 0 ${space.m};
  border-radius: ${radius.pill};
  opacity: 0.5;
  &:hover {
    opacity: 1;
    background-color: ${colors.primary};
    cursor: pointer;
    p {
      color: ${colors.white};
      opacity: 1;
    }
  }
  ${({ disabled }) =>
    disabled &&
    ` opacity: 1;
    pointer-events: none;
      &:hover {
        background-color: transparent;
        cursor: default;
      }`}
`

const PaginationItemDots = styled(Flex)`
  height: 48px;
  width: 48px;
  min-width: 48px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.primary};
  text-align: center;
  margin: ${space.s} ${space.m} 0 ${space.m};
  border-radius: ${radius.pill};
  opacity: 0.5;
  &:hover {
    background-color: transparent;
    cursor: default;
    p {
      color: ${colors.primary};
    }
  }
`

const PaginationArrow = styled(Flex)`
  height: 48px;
  width: 48px;
  min-width: 48px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.primary};
  text-align: center;
  border-radius: ${radius.pill};
  opacity: 0.5;
  cursor: pointer;
  margin: ${space.s} 0 0 0;
  pointer-events: none;
  ${({ active }) =>
    active &&
    ` opacity: 1;
     background-color: ${colors.primary};
     pointer-events: initial; 
    p {
      color: ${colors.white};
      opacity: 1;
    }`}
`

const PaginationComponent = ({ onPageChange, totalCount, currentPage, pageSize }) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount: 1,
    pageSize,
  })

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    const updatedPage = currentPage + 1
    if (updatedPage <= totalCount) {
      onPageChange(updatedPage)
    }
  }

  const onPrevious = () => {
    const updatedPage = currentPage - 1
    if (updatedPage >= 1) {
      onPageChange(updatedPage)
    }
  }

  const lastPage = paginationRange[paginationRange.length - 1]

  return (
    <Flex justifyContent={{ mobile: 'center', tablet: 'space-between' }} alignItems="center">
      <PaginationArrow onClick={onPrevious} disabled={currentPage === 1} active={currentPage !== 1}>
        <Text color="primary" fontSize="m" fontWeight="bold">
          {'<'}
        </Text>
      </PaginationArrow>
      <Flex flexWrap="wrap" justifyContent="center">
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <PaginationItemDots>
                <Text fontSize="m" fontWeight="bold" opacity={0.5} color="primary">
                  &#8230;
                </Text>
              </PaginationItemDots>
            )
          }

          const selected = pageNumber === currentPage

          return (
            <PaginationNumber key={pageNumber} disabled={selected} onClick={() => onPageChange(pageNumber)}>
              <Text fontSize="m" fontWeight="bold" opacity={selected ? 1 : 0.5} color="primary">
                {pageNumber < 10 ? `0${pageNumber}` : pageNumber}
              </Text>
            </PaginationNumber>
          )
        })}
      </Flex>

      <PaginationArrow onClick={onNext} active={currentPage !== lastPage}>
        <Text color="primary" fontSize="m" fontWeight="bold">
          {'>'}
        </Text>
      </PaginationArrow>
    </Flex>
  )
}

PaginationComponent.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
}

export default PaginationComponent
