import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR_CONSTANTS, space, radius } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import { parseTextFromServer, countArticleReadingTime, stripHtml } from 'helpers'
import { toHumanReadable } from 'utils/date'
import { Flex, Grid } from 'components/Layout'
import { Text, H2 } from 'components/Typography'
import LocalImage from 'components/LocalImage'
import Container from 'components/Container'
import CategoryItem from './CategoryItem'
import BlogItem from './BlogItem'

const StyledFeaturedArticleWrapper = styled(Flex)`
  border: 1px solid #b6cef0;
  border-radius: 12px;
  padding: ${space.m};
  &:hover {
    box-shadow: 0px 0px 10px rgba(145, 158, 171, 0.3);
    h2 {
      text-decoration: underline;
    }
  }
`

const StyledArticleImage = styled(LocalImage)`
  border-radius: ${radius.l};
`

const StyledTitleH2 = styled(H2)`
  display: flex;
  align-items: center;
  height: 100%;
  &:hover {
    text-decoration: underline;
  }
`

const StyledFeaturedTitleH2 = styled(H2)`
  &:hover {
    text-decoration: underline;
  }
`

const HeaderSection = ({ articles }) => {
  const firstArticle = articles[0]
  const elseArticles = articles.slice(1, 4)
  const mobileArticles = articles.slice(1, 5)

  const { featured_media, date: createdAt, title, slug, categories, content } = firstArticle

  const readingTime = countArticleReadingTime({ text: stripHtml(content) })
  const parsedTitle = parseTextFromServer(title)

  return (
    <Container
      mt="l"
      maxWidth={CONTAINER_MAX_WIDTH}
      pl={{ mobile: 'l', tablet: 'l' }}
      pr={{ mobile: 'l', tablet: 'l' }}
    >
      <Flex flexDirection={{ mobile: 'column', desktop: 'row' }}>
        <Flex maxWidth={{ mobile: '100%', desktop: '524px' }} flexDirection="column">
          {/*<Flex mb="m" display={{ mobile: 'flex', desktop: 'none' }}>*/}
          {/*  <Text color="secondaryText" fontSize="l">*/}
          {/*    Featured Article*/}
          {/*  </Text>*/}
          {/*</Flex>*/}
          <Flex mb="m">
            <Text color="secondaryText" fontSize="xl">
              Featured Article
            </Text>
          </Flex>

          <StyledFeaturedArticleWrapper flexDirection="column" width="100%">
            <Flex as="a" href={`/insights/${slug}`}>
              <StyledArticleImage src={featured_media.source_url} width="100%" alt={parsedTitle} />
            </Flex>

            <Flex mt="m" justifyContent="space-between">
              <Text color="secondaryText">{toHumanReadable(createdAt)}</Text>
              <Text color="secondaryText">{readingTime} min read</Text>
            </Flex>
            <StyledFeaturedTitleH2
              mt="m"
              fontWeight="bold"
              fontSize="xxxl"
              color={COLOR_CONSTANTS.DENIM}
              as="a"
              href={`/insights/${slug}`}
            >
              {parseTextFromServer(parsedTitle)}
            </StyledFeaturedTitleH2>
            <Flex mt="m" pt="s" flexWrap="wrap">
              {categories.map((category, index) => (
                <CategoryItem key={index} category={category} />
              ))}
            </Flex>
          </StyledFeaturedArticleWrapper>
        </Flex>
        <Flex ml={{ mobile: 0, desktop: 'l' }} maxWidth={{ mobile: '100%', desktop: '524px' }} flexDirection="column">
          <Flex
            flexDirection="column"
            height="100%"
            justifyContent="space-between"
            display={{ mobile: 'none', desktop: 'flex' }}
          >
            <Flex mb="m">
              <Text color="secondaryText" fontSize="xl">
                Editor’s Picks
              </Text>
            </Flex>
            <Flex flexDirection="column" height="100%" justifyContent="space-between" gridGap="s">
              {elseArticles.map(({ featured_media, date: createdAt, title, content, slug, categories }) => {
                const parsedTitle = parseTextFromServer(title)
                const readingTime = countArticleReadingTime({ text: stripHtml(content) })
                return (
                  <Flex key={parsedTitle} flexDirection="column">
                    <Flex>
                      <Flex as="a" href={`/insights/${slug}`}>
                        <StyledArticleImage src={featured_media.source_url} width="200px" alt={parsedTitle} />
                      </Flex>
                      <Flex flexDirection="column" ml="m">
                        <Flex justifyContent="space-between">
                          <Text color="secondaryText">{toHumanReadable(createdAt)}</Text>
                          <Text color="secondaryText">{readingTime} min read</Text>
                        </Flex>
                        <StyledTitleH2
                          fontWeight="bold"
                          fontSize="xl"
                          color={COLOR_CONSTANTS.DENIM}
                          as="a"
                          href={`/insights/${slug}`}
                        >
                          {parseTextFromServer(parsedTitle)}
                        </StyledTitleH2>
                      </Flex>
                    </Flex>
                    <Flex flexWrap="wrap">
                      {categories.map((category, index) => (
                        <CategoryItem key={index} category={category} buttonStyle={{ marginTop: space.xs }} />
                      ))}
                    </Flex>
                  </Flex>
                )
              })}
            </Flex>
          </Flex>

          <Flex mb="m" mt="l" display={{ mobile: 'flex', desktop: 'none' }}>
            <Text color="secondaryText" fontSize="m">
              Editor’s Picks
            </Text>
          </Flex>
          <Grid
            gridTemplateColumns={{ mobile: '1fr', tablet: 'repeat(2, 1fr)' }}
            gridGap="m"
            display={{ mobile: 'grid', desktop: 'none' }}
          >
            {mobileArticles.map((article) => (
              <BlogItem key={article.id} {...article} />
            ))}
          </Grid>
        </Flex>
      </Flex>
    </Container>
  )
}

HeaderSection.propTypes = {
  articles: PropTypes.array.isRequired,
}

export default HeaderSection
